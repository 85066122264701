.slider {
    width: 100%;
    height: 280px;
    position: relative;
    margin: 0px auto 0px auto;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.settemotivi-logo {
    position: absolute;
    bottom: 25px;
    left: 25px;
    height: 80px;
    width: 80px;
    opacity: 0.9;
    border-radius: 50%;
    overflow: hidden;
}

.settemotivi-logo img {
    width: 100%;
    height: 100%;
}