.aside-container {
    float: right;
    width: 20%;
    background-color: rgb(234, 240, 184);
    font-size: 1rem;
    text-align: center;
    padding-bottom: 50px;
}

.five-x-container {
    display: inline-block;
    height: 200px;
    width: 90%;
    margin: 50px auto 70px auto;
    overflow: hidden;
    cursor: pointer;
}

.five-x-container:hover {
    transform: scale(1.01);
}

.radio-cosmo-container {
    display: inline-block;
    width: 90%;
    background-color: #fff;
    padding: 15px 0;
}

.radio-cosmo-site {
    display: block;
    height: 100%;
    width: 100%;
    margin: 0px auto 10px auto;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.radio-cosmo-site:hover {
    transform: scale(1.01);
}

.logo-fit {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

#radio-cosmo-live {
    display: inline-block;
    margin: 0 auto;
    width: 90%;
    height: 40px;
    background: linear-gradient(90deg, rgba(209, 96, 58, 1) 0%, rgba(75, 111, 184, 1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
}

#radio-cosmo-live p {
    color: #fff;
}

#radio-cosmo-live:hover {
    background: linear-gradient(90deg, rgba(219,87,42,1) 0%, rgba(54,106,212,1) 100%);
    transform: scale(1.01);
}