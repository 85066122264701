nav {
    background-color: rgb(224, 224, 224);
    max-width: 100%;
    border-color: darkgray;
    border-style: none solid solid solid;
    border-width: 0 2px 2px 2px;
    border-radius: 0 0 100px 7px;
}

nav ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

nav li {
    display: inline-block;
    padding: 8px;
}

nav a {
    text-decoration: none;
}

.subnav {
    position: relative;
}

.subnav img {
    width: 16px;
    height: 16px;
}

.subnav:hover .submenu {
    display: block;
}

.first-level-menu {
    margin: 5px 15px;
    cursor: pointer;
    font-weight: 500;
}

.first-level-menu:hover {
    background-color: rgb(200, 200, 200);
    transition: 0.15s;
}

.logo-menu {
    border-radius: 50%;
}

.menu-divider {
    display: inline;
    height: 21px;
    border-left: 1px solid black;
}

.link-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
}

.submenu {
    position: absolute;
    display: none;
    background-color: rgb(236, 236, 236);
    top: 100%;
    left: 0;
    z-index: 1;
}

.second-level-menu {
    padding: 9px 13px;
    display: block;
    text-align: left;
    text-wrap: nowrap;
}

.second-level-menu:hover {
    background-color: rgb(218, 218, 218);
}

.social-link {
    width: 27px;
    height: 27px;
}

.social-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}